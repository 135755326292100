<template>
  <div>
    <AppLayoutHeader :hide-hamburger-button="true" />
    <div class="form-container px">
      <Stage :stage="3" />
      <div class="pa-3">
        <h2 class="--prm --w-bold text-center">
          {{ $t('text.stage.confirmation') }}
        </h2>
        <p class="mt-6 text-title--szm --dark --w-medium">
          {{ $t('text.register.pleaseConfirm') }}
        </p>

        <div class="bg-smoke mt-8 px-4">
          <v-row class="ma-0">
            <v-col class="pa-0 py-4 text-normal --w-bold --dark">{{ $t('common.loginID') }}</v-col>
            <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">{{ data.email }}</v-col>
          </v-row>
          <v-divider />

          <v-row class="ma-0">
            <v-col class="pa-0 py-4 text-normal --w-bold --dark">{{ $t('common.emailAddress') }}</v-col>
            <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">{{ data.email }}</v-col>
          </v-row>
          <v-divider />

          <v-row class="ma-0">
            <v-col class="pa-0 py-4 text-normal --w-bold --dark">{{ $t('common.password') }}</v-col>
            <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">●●●●●●●</v-col>
          </v-row>
          <v-divider />

          <v-row class="ma-0">
            <v-col class="pa-0 py-4 text-normal --w-bold --dark">{{ $t('contractDetail.membershipNumber') }}</v-col>
            <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">{{ data.memberInfo.membershipNumber ? data.memberInfo.membershipNumber : '—' }}</v-col>
          </v-row>
          <v-divider />

          <template v-if="!data.isCompany">
            <v-row class="ma-0">
              <v-col class="pa-0 py-4 text-normal --w-bold --dark">{{ $t('common.fullName') }}</v-col>
              <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">
                （姓）{{ data.memberInfo.surname }}
                <br>
                （名）{{ data.memberInfo.name }}
              </v-col>
            </v-row>
            <v-divider/>

            <v-row class="ma-0">
              <v-col class="pa-0 py-4 text-normal --w-bold --dark">{{ $t('common.nameKana') }}</v-col>
              <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">
                （セイ）{{ data.memberInfo.sei }}
                <br>
                （メイ）{{ data.memberInfo.mei }}
              </v-col>
            </v-row>
            <v-divider/>

            <v-row class="ma-0">
              <v-col class="pa-0 py-4 text-normal --w-bold --dark">{{ $t('common.sex') }}</v-col>
              <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">{{ sex }}</v-col>
            </v-row>
            <v-divider/>

            <v-row class="ma-0">
              <v-col class="pa-0 py-4 text-normal --w-bold --dark">{{ $t('common.dateOfBirth') }}</v-col>
              <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">
                {{ data.memberInfo.year }}年{{ data.memberInfo.month }}月{{ data.memberInfo.day }}日
              </v-col>
            </v-row>
            <v-divider/>
          </template>
          <template v-else>
            <v-row class="ma-0">
              <v-col class="pa-0 py-4 text-normal --w-bold --dark">法人名</v-col>
              <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">{{ data.memberInfo.corporationName }}</v-col>
            </v-row>
            <v-divider/>

            <v-row class="ma-0">
              <v-col class="pa-0 py-4 text-normal --w-bold --dark">法人名フリガナ</v-col>
              <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">{{ data.memberInfo.corporateName }}</v-col>
            </v-row>
            <v-divider/>

            <v-row class="ma-0">
              <v-col class="pa-0 py-4 text-normal --w-bold --dark">法人名番号</v-col>
              <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">{{ data.memberInfo.corporateNameNo }}</v-col>
            </v-row>
            <v-divider/>
            <v-row class="ma-0">
              <v-col class="pa-0 py-4 text-normal --w-bold --dark">ご担当者名</v-col>
              <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">{{ data.memberInfo.nameOfPersonInCharge }}</v-col>
            </v-row>
            <v-divider/>

            <v-row class="ma-0">
              <v-col class="pa-0 py-4 text-normal --w-bold --dark">ご担当者名（カナ）</v-col>
              <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">{{ data.memberInfo.katakana }}</v-col>
            </v-row>
            <v-divider/>
            <v-row class="ma-0">
              <v-col class="pa-0 py-4 text-normal --w-bold --dark">ご担当者部署</v-col>
              <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">{{ data.memberInfo.department }}</v-col>
            </v-row>
            <v-divider/>
            <v-row class="ma-0">
              <v-col class="pa-0 py-4 text-normal --w-bold --dark">ご担当者役職</v-col>
              <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">{{ data.memberInfo.position }}</v-col>
            </v-row>
            <v-divider/>
          </template>

          <v-row class="ma-0">
            <v-col class="pa-0 py-4 text-normal --w-bold --dark">{{ $t('common.postalCode') }}</v-col>
            <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">{{ data.memberInfo.postalCode }}</v-col>
          </v-row>
          <v-divider />

          <v-row class="ma-0">
            <v-col class="pa-0 py-4 text-normal --w-bold --dark">{{ $t('text.register.yourAddress') }}</v-col>
            <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">
              {{ data.memberInfo.province }} {{ data.memberInfo.district }} {{ data.memberInfo.street }} {{ data.memberInfo.buildingName }}
            </v-col>
          </v-row>
          <v-divider />

          <v-row class="ma-0">
            <v-col class="pa-0 py-4 text-normal --w-bold --dark">{{ $t('common.contactAddress') }}</v-col>
            <v-col class="pa-0 py-4 text-medium-szm --w-regular --dark">
              <div v-for="(num, ind) in phoneNumbers" :key="`tel${ind}`">
                {{ num }}
              </div>
            </v-col>
          </v-row>
          <v-divider />
        </div>

        <div class="bg-smoke mt-8 px-4">
          <v-row class="ma-0">
            <v-col class="pa-0 py-4 text-normal --w-bold --dark">{{ $t('common.cardNumber') }}</v-col>
            <v-col class="pa-0 py-4 text-small --w-regular --dark">●●●● - ●●●● - ●●●● - {{ data.cardData.last4Digits }}</v-col>
          </v-row>
          <v-divider/>

          <v-row class="ma-0">
            <v-col class="pa-0 py-4 text-normal --w-bold --dark">{{ $t('menu.main.items.expirationDate') }}</v-col>
            <v-col class="pa-0 py-4 text-small --w-regular --dark">●● / ●●●●</v-col>
          </v-row>
          <v-divider/>

          <v-row class="ma-0">
            <v-col class="pa-0 py-4 text-normal --w-bold --dark">{{ $t('common.securityCode') }}</v-col>
            <v-col class="pa-0 py-4 text-small --w-regular --dark">●●●</v-col>
          </v-row>
          <v-divider/>

          <v-row class="ma-0">
            <v-col class="pa-0 py-4 text-normal --w-bold --dark">{{ $t('common.awardCode') }}</v-col>
            <v-col class="pa-0 py-4 text-small --w-regular --dark">{{ data.cardData.promoCode ? data.cardData.promoCode : '—' }}</v-col>
          </v-row>
          <v-divider/>
        </div>

        <calculated-fee :promo-code="data.cardData.promoCode" :token="data.token" />
        <vs-signup-confirmation-form v-model="form" ref="form" />

        <template v-if="backButton">
          <v-btn @click="backButton.click"
            outlined
            rounded
            large
            block
            class="bg-white --prm-light mt-4 mb-5 text-title"
          >{{backButton.text}}</v-btn>
        </template>
        <v-btn
          elevation="0"
          class="btn-icon shadow-white mt-4 mb-5 text-heading"
          color="btn-grad--orange"
          @click="commit()"
          block
          rounded
          x-large
        >
          <span class="text-center w-100">{{ $t('buttons.register') }}</span>
          <v-icon color="var(--color__white)">mdi-chevron-right-circle-outline</v-icon>
        </v-btn>
        <p class="--red text-medium-szm --w-regular">{{ $t('text.register.pleaseSure') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Stage from '@/components/StageRegister'
import AppLayoutHeader from '@/layout/AppLayout/components/AppLayoutHeader'
import { zeroPad } from '@/utils/format'
import { sessionStorageMixin } from '@/mixins/session-storage.mixin'
import commonConstants from '@/views/Register/common.constants'
import CalculatedFee from './CalculatedFee.vue'
import VsSignupConfirmationForm from './VsSignupConfirmationForm.vue'

const PAYMENT_ERROR = 'PAYMENT_ERROR'

export default {
  components: {
    AppLayoutHeader,
    Stage,
    CalculatedFee,
    VsSignupConfirmationForm
  },
  mixins: [sessionStorageMixin],
  data () {
    return {
      data: {
        ...this.getFromStorage(commonConstants.storageKey)
      },
      form: null,
      error: null
    }
  },
  computed: {
    sex () {
      if (this.data.memberInfo.gender === 'Male') {
        return this.$t('common.male')
      } else if (this.data.memberInfo.gender === 'Female') {
        return this.$t('common.female')
      } else {
        return this.$t('common.other')
      }
    },
    phoneNumbers () {
      return [this.data.memberInfo.phoneNumber, this.data.memberInfo.cellNumber]
    },
    backButton () {
      if (this.error === PAYMENT_ERROR) {
        return {
          text: '支払方法の登録へ戻る',
          click: () => this.$router.replace({ name: 'register-payment-method' })
        }
      } else {
        return null
      }
    }
  },
  methods: {
    async commit () {
      this.error = null
      if (this.$refs.form.validate()) {
        await this.$doLoading(async () => {
          await this.$showGqlError(async () => {
            const result = await this.$store.dispatch('regSubmit', {
              apollo: this.$apollo,
              isCompany: this.data.isCompany,
              model: {
                isCompany: this.data.isCompany,
                clientCode: this.data.memberInfo.membershipNumber,
                password: this.data.memberInfo.password,
                email: this.data.email,
                token: this.data.token,
                postalCode: this.data.memberInfo.postalCode,
                prefecture: this.data.memberInfo.province,
                locality: this.data.memberInfo.district,
                address1: this.data.memberInfo.street,
                address2: this.data.memberInfo.buildingName,
                mobile: this.data.memberInfo.cellNumber,
                tel: this.data.memberInfo.phoneNumber,
                creditCardToken: this.data.cardData.cardToken,
                promoCode: this.data.cardData.promoCode,
                firstName: this.data.memberInfo.name,
                lastName: this.data.memberInfo.surname,
                birthday: `${this.data.memberInfo.year}-${zeroPad(this.data.memberInfo.month, 2)}-${zeroPad(this.data.memberInfo.day, 2)}`,
                firstKana: this.data.memberInfo.mei,
                lastKana: this.data.memberInfo.sei,
                sex: this.data.memberInfo.gender,
                corporationName: this.data.memberInfo.corporationName,
                corporateName: this.data.memberInfo.corporateName,
                corporateNameNo: this.data.memberInfo.corporateNameNo,
                nameOfPersonInCharge: this.data.memberInfo.nameOfPersonInCharge,
                katakana: this.data.memberInfo.katakana,
                department: this.data.memberInfo.department,
                position: this.data.memberInfo.position,
                media: this.form.media
              }
            })
            // Remove saved registration data
            this.removeFromStorage(commonConstants.storageKey)
            if (result.result === 'WelcomeEmailSent') {
              // success
              await this.$router.push('/register/complete')
            } else if (result.result === 'StaffWillContact') {
              // the client is possibly duplicated. so
              this.$router.push({ name: 'register-complete-wait' })
            } else {
              // unknown state, shouldn't happen
              this.$router.push({ name: 'register-complete-wait' })
            }
          }, {
            INVALID_CC_INFO: this.handlePaymentError,
            CC_TEMPORARILY_UNAVAILABLE: this.handlePaymentError,
            CC_INVALID_TOKEN: this.handlePaymentError
          })
        })
      }
    },
    handlePaymentError (_, { toast }) {
      this.error = PAYMENT_ERROR
      toast()
    }
  }
}
</script>
