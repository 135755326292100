<template>
  <div>
    <div>
      <v-row class="ma-0 mt-16">
        <v-col cols="8" class="pa-0 pt-1 text-heading --w-bold --prm">{{ $t('common.membershipFee') }}</v-col>
        <v-col class="pa-0 text-right text-x-heading --w-bold --dark">{{ membershipFee | toThousands }}円</v-col>
      </v-row>
      <v-divider class="mt-3 --border-prm" />
      <v-row class="ma-0 mt-3">
        <template v-if="benefitsApplied || awardedPoint">
          <v-col class="pa-0 text-medium-szm --w-bold text-right">{{ $t('common.benefitsApplied') }}</v-col>
          <v-col class="pa-0 text-medium-szm --w-regular text-right">
            <span v-if="benefitsApplied">-{{ benefitsApplied | toThousands }}円<br></span>
            <span v-if="awardedPoint">{{ awardedPoint | toThousands }}ポイント付与</span>
          </v-col>
        </template>
      </v-row>
    </div>

    <div>
      <v-row class="ma-0 mt-16">
        <v-col cols="8" class="pa-0 pt-1 text-heading --w-bold --prm">{{ $t('common.total') }}</v-col>
        <v-col class="pa-0 text-right text-x-heading --w-bold --dark">{{ total | toThousands }}円</v-col>
      </v-row>
      <v-divider class="mt-3 --border-prm" />
      <div class="pa-0 mt-3 text-medium-szm --w-bold text-right" v-if="awardedPoint">
        {{ awardedPoint | toThousands }}ポイント付与
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
export default {
  props: {
    promoCode: String,
    token: String
  },
  data () {
    return {
      membershipFee: null,
      benefitsApplied: null,
      awardedPoint: null,
      total: null
    }
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        const data = await this.$apollo
          .mutate({
            mutation: gql`
              query ($promoCode: String $token: String)
              {
                getVsAnnualFee(promoCode: $promoCode token: $token)
                {
                  total
                  promoTVP
                  baseFee # Int! the base fee (年会費 in the mockup)
                  promoDiscount # Int! the discount (
                }
              }
            `,
            variables: {
              promoCode: this.promoCode || undefined,
              token: this.token
            }
          })
        this.membershipFee = data.data.getVsAnnualFee.baseFee
        this.benefitsApplied = data.data.getVsAnnualFee.promoDiscount
        this.awardedPoint = data.data.getVsAnnualFee.promoTVP
        this.total = data.data.getVsAnnualFee.total
      })
    })
  }
}
</script>
