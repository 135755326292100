export const sessionStorageMixin = {
  methods: {
    saveToStorage (key, value) {
      sessionStorage.setItem(key, JSON.stringify(value))
    },
    getFromStorage (key) {
      return JSON.parse(sessionStorage.getItem(key))
    },
    removeFromStorage (key) {
      return sessionStorage.removeItem(key)
    }
  }
}
