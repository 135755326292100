<template>
  <div>
    <div class="bg-white py-5 px-4 d-flex">
      <div class="text-center">
        <p class="item-circle bg-silver text-heading --white ml-3" :class="{'bg-primary': stage == 1 }">1</p>
        <p class="mt-2 text-default --black --w-regular" :class="{'--prm': stage == 1}">
          {{ label.memberInformation }}</p>
      </div>
      <v-divider class="mt-4"/>
      <div class="text-center">
        <p class="item-circle bg-silver text-heading --white ml-6" :class="{'bg-primary': stage == 2 }">2</p>
        <p class="mt-2 text-default --black --w-regular" :class="{'--prm': stage == 2}">{{ label.paymentMethod }}</p>
      </div>
      <v-divider class="mt-4"/>
      <div class="text-center">
        <p class="item-circle bg-silver text-heading --white mx-1" :class="{'bg-primary': stage == 3 }">3</p>
        <p class="mt-2 text-default --black --w-regular" :class="{'--prm': stage == 3}">{{ label.confirmation }}</p>
      </div>
      <v-divider class="mt-4"/>
      <div class="text-center">
        <p class="item-circle bg-silver text-heading --white ml-1" :class="{'bg-primary': stage == 4 }">4</p>
        <p class="mt-2 text-default --black --w-regular ml-1" :class="{'--prm': stage == 4}">{{ label.complete }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Stage',
  props: {
    stage: {
      type: Number,
      default: 1
    }
  },
  components: {},
  data () {
    return {
      label: {
        memberInformation: this.$t('text.stage.memberInformation'),
        paymentMethod: this.$t('text.stage.paymentMethod'),
        confirmation: this.$t('text.stage.confirmation'),
        complete: this.$t('text.stage.complete')
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.item-circle {
  width: 33px;
  height: 33px;
  border-radius: 50%;
}
</style>
