<template>
  <terms>
    Vacation Style 会員規約<br>
（本会員規約）<br>
第１条 この「Vacation Style 会員規約」（以下「本会員規約」という。）は、東急株式会社<br>
（以下「当社」という。）が運営する会員制度「Vacation Style」（以下「本会」という。）に<br>
関する規約を定めたものです。<br>
２ 第２条に定める手続きにもとづき本会に入会された会員（以下「本会員」という。）は、<br>
本会員規約に定めるサービス（以下「本会員サービス」という。）を利用することができま<br>
す。<br>
３ 本会員は、本会員規約および本会員規約に付随する各種規約等（本会員規約に付随する<br>
別紙に記載する各種規約等をいい、本会員規約と総称して、以下「本会員規約等」という。）<br>
を遵守するものとします。<br>
４ 本会員が当社の運営する別途当社が定める「Vacation Masters 会員規約」（以下「VM 会<br>
員規約」という。）にもとづく会員制度（以下「VM 会員制度」という。）に入会する場合（当<br>
該入会後の VM 会員規約上の本会員を以下「VM 会員」という。）、次条第２項に定める本<br>
会員契約は終了し、本会員は、本会員の資格を喪失するものとします。ただし、資格喪失前<br>
において本会員が保有していた第８条第５項第３号に定める本ポイント（以下「VM 入会時<br>
ポイント」という。）については、VM 会員規約第６条第５号第３号に定める VM 会員制度<br>
上の東急バケーションズポイント（以下「VM 会員制度上のポイント」という。）として移<br>
行されるものとし、 VM 会員規約第８条第４項に定めるポイントの付与として、VM 入会<br>
時ポイント相当のポイント数が VM 会員に付与されるものとします。<br>
（入会）<br>
第２条 本会への入会を希望するお客様（以下「入会希望者」という。）は、東急バケーシ<br>
ョンズのウェブサイト（http//tokyu-vacations.com 以下「当社サイト」という。）上の申込<br>
ページ（以下「申込サイト」という。）において申込フォームに必要事項を入力し、本会員<br>
規約の内容を承諾のうえ、入会手続きを行うものとします。<br>
２ 前項の申込の内容を当社が承諾して、申込サイト上に「登録完了」の旨が表示されたと<br>
きに、前項の申込内容および本会員規約にもとづく会員契約（以下「本会員契約」という。）<br>
は成立し、入会希望者は本会員契約成立と同時に本会員となります（当該契約成立日を以下<br>
「入会日」という。）。<br>
３ 本会員契約の契約期間は、入会日から、入会日の翌月１日から１年経過した日までとし、<br>
当社が更新を拒絶した場合を除き、契約満了日の翌日から更に１年間自動更新されるもの<br>
とし、以後同様とします。ただし、自動更新を希望しない場合は契約満了日の前日までに第<br>
７条第１項に定めるマイページ上の自動更新フラグをオフにすることにより、契約満了日<br>
を以って退会することができます。<br>
４ 本会員は、事由の如何を問わず、本会員契約が終了した場合、本会員の資格を喪失しま<br>
（入会希望者の要件）<br>
第３条 入会希望者は、次項各号に該当せず、次の各号のいずれかに該当し、日本国内発行<br>
の Visa・Mastercard・JCB・ダイナースクラブのいずれかのクレジットカードを所有してい<br>
る場合に限り、本会への入会を申込むことができるものとします。<br>
（１）日本国内に住民登録を有する成人<br>
（２）日本国内で法人登記された法人<br>
２ 入会希望者は、次の各号のいずれかの者に該当していないことを、当社に確約のうえ、<br>
次条に従い申込み手続きをします。<br>
（１）第２５条第１項に定める反社会的勢力に該当する者<br>
（２）過去５年間に当社との契約に違反した者<br>
（３）当社が不適格な者として別途定めた者<br>
３ 入会希望者は、本会員サービスを利用するためには、第７条第１項に定めるマイページ<br>
を使用することが必須であるため、マイページを使用するために必要な機器、ソフトウェア<br>
および通信手段等を、自らの費用と責任で用意し、維持管理等を行う必要があることを承知<br>
して、入会の申込みをするものとします。<br>
（クレジットカード登録）<br>
第４条 入会希望者は、第２条第１項の入会申込手続きの際に、自らの責任にて、申込サイ<br>
トにて表示される当社指定の第三者のクレジットカード決済手続き会社に別途申し込みを<br>
して、次の各号の当社への支払いに使用する入会希望者名義のクレジットカードの登録（以<br>
下、当該登録されたクレジットカードを「登録カード」という。）を行わなければならない。<br>
なお、当社は登録カードにより、次の各号記載の支払い代金を徴収できるものとします。<br>
（１）第５条第１項に定める年会費<br>
（２）第８条第５項に定める東急バケーションズ施設の宿泊料<br>
（３）前各号のほか、本会員規約等に定めるサービスの対価等<br>
（４）前各号のほか、本会員規約等にもとづき本会員が当社に期日までに支払わなかった金<br>
銭債務<br>
２ 本会員は、登録カードについて、前項各号の支払いが適時適正に実施されるように維持<br>
するものとします。なお、本会員は、登録カードが更新され、または変更する場合には、第<br>
７条第１項に定めるマイページを使用して、必要な手続きを行うものとします。<br>
（メンバーシップフィーおよび支払方法）<br>
第５条 メンバーシップフィー（以下「年会費」という。）は年額とし、次の額とします。<br>
なお、端数が生じる年でも日割り計算等は行わないものとします。<br>
 メンバーシップフィー： 金４，４００円／年（消費税および地方消費税込み）<br>
２ 年会費の額は、税法、経済事情の変化等に応じて、当社はこれを改定できるものとしま<br>
す。<br>
３ 年会費は、入会日から、入会日の属する月の末日までについては無料とし、本会員は、<br>
毎年、入会日の月日の属する月の翌月１日（以下「支払期日」という。）までに、年会費を<br>
当社に支払うものとします。なお、年会費は、当社が、毎年、支払期日に、登録カードより<br>
徴収します。<br>
（本会員サービス）<br>
第６条 本会員サービスは、次の各号のサービスとします。<br>
（１）マイページの使用（詳細は第７条のとおりとします。）<br>
（２） 東急バケーションズ施設の優遇（詳細は第８条のとおりとします。）<br>
（３）提携施設の宿泊に関する旅行の優遇（詳細は第９条のとおりとします。）<br>
（４）ポイント付与、保有、利用（詳細は第１０条のとおりとします。）<br>
（５）ファミリー会員制度によるサービス（詳細は第１１条のとおりとします。）<br>
（６）前各号の他当社が第 7 条第 1 項に定めるマイページ上において随時案内する当社ま<br>
たは第三者が提供するサービス<br>
（マイページの使用）<br>
第７条 本会員は、当社が別途定める「Vacation 会員ページ規約」 （以下「マイページ規<br>
約」という。）に従って、マイページ規約第１条第１項に規定する本会員専用のウェブペー<br>
ジであるマイページ（以下「マイページ」という。）を使用することによって、次の各号の<br>
事項を実施できます。<br>
（１）本会員規約に定める各種手続き（ただし、一部手続きを除く。）<br>
（２）本会員サービスの申込み手続き、手続き状況の確認等（ただし、一部手続き等を除く。）<br>
２ 本会員は、マイページにて実施できる本会員サービスの申込手続き等については、マイ<br>
ページを使用して行うものとします。なお、マイページを使用してできない手続き等につい<br>
ては、マイページ上に定める方法に従って行うものとします。<br>
３ 当社は、第１１条第 1 項に規定するファミリー会員に対し、本会員の責任において、フ<br>
ァミリー会員用の機能限定のマイページ（以下、本会員のマイページの部として扱うものと<br>
します。）を使用することを認めます。本会員は、自らの責任により、ファミリー会員に対<br>
し、本会員規約等を遵守させるものとします。<br>
（東急バケーションズ施設の優遇）<br>
第８条 本会員は、当社が１泊単位で別途一般向けに販売する、当社が運営する宿泊施設<br>
「東急バケーションズ施設」（以下「東急バケーションズ施設」という。）の空き客室（以下<br>
「本空室」という。）を、一般と比較して優遇した時期および宿泊料により宿泊予約できま<br>
す。なお、当該宿泊に関する契約は、本会員規約とは別の契約になり、当社が別途定める各<br>
東急バケーションズ施設の宿泊約款が適用される契約となります。<br>
２ 本会員は、マイページを使用して、本空室の状況および宿泊料を確認することができ、<br>
本空室の予約をするにあたっては、マイページ上に表示される本空室の東急バケーション<br>
ズ施設の宿泊約款その他の条件（本条に限り、総称して以下「本宿泊条件」という。）を確<br>
認、同意のうえ、マイページに表示される手続きに従い、申込みを行うものとします。なお、<br>
本条による宿泊については、本会員または本会員が同伴する者のみ宿泊できるものとし、第<br>
三者のみの宿泊はできないものとします。また、法人の本会員の場合には、当該法人の役職<br>
員または役職員が同伴するその家族（自らの両親、配偶者、配偶者の両親、子、子の配偶者<br>
に限る。以下同様とする。）のみ宿泊できるものとします。<br>
３ 前項の申込の内容を当社が承諾して、マイページ上に「予約完了」の旨が表示されたと<br>
きに、前項の申込内容に関する宿泊契約（以下「本宿泊契約」という。）は申込内容および<br>
本宿泊条件にもとづき本会員と当社との間で成立します。<br>
４ 本会員は、前項の手続きにより確定した予約の内容を、マイページを使用して確認する<br>
ことができ、マイページ上に表示される方法にて、当該予約を取り消す（キャンセルする）<br>
ことができます。なお、当該キャンセルについては、本宿泊契約に定める取消料（キャンセ<br>
ル料）がかかる場合があります。<br>
５ 本会員は、次の各号の方法（詳細はマイページに定める。）により、本宿泊契約におけ<br>
る宿泊料を、本宿泊契約にもとづき当社に支払うものとします。<br>
（１）登録カードからの支払い<br>
（２）東急バケーションズ施設での現金での支払い<br>
（３）当社が別途定める「東急バケーションズポイント規約」 （以下「ポイント規約」と<br>
いう。）に定める東急バケーションズポイント（以下「本ポイント」という。）での支払い<br>
（４）前各号の他、当社が定める支払い方法<br>
（提携施設の宿泊に関する旅行の優遇）<br>
第９条 本会員は、マイページ上において当社が本会員向けに案内する、当社が１泊単位で<br>
販売する東急バケーションズ施設以外の当社が提携する宿泊施設（以下「本提携宿泊施設」<br>
といい、東急バケーション施設と総称して「本施設等」という。）への宿泊の手配旅行また<br>
は企画旅行（総称して、以下「当社旅行商品」という。）に申込むことができます。<br>
２ 本会員は、前項の申込みについては、マイページに定める方法に従い、本提携宿泊施設<br>
の空室の状況および当社旅行商品の宿泊料その他の旅行条件書、および当社の旅行契約約<br>
款（本条に限り、総称して以下「本宿泊条件」という。）を確認、同意のうえ、行うものと<br>
します。<br>
３ 本会員が前項の手続きによって申込みを行い、当社からの手続き完了の発信をもって、<br>
本宿泊条件にもとづく当社旅行商品に関する契約（以下「当社旅行契約」という。）が本会<br>
員と当社との間で成立するものとします。なお、当社旅行契約は、本会員規約とは別の独立<br>
した契約になります。なお、本会員が同行しない旅行はできません。また、本会員が法人の<br>
場合には、当該法人の役職員または役職員がその家族を同伴するときのみ旅行できるもの<br>
とします。<br>
４ 当社旅行商品の旅行代金の支払方法は、本ポイントでのお支払方法のみとなり、当社旅<br>
行契約が成立した場合、当社が、本会員が保有する本ポイントから、当社旅行契約にもとづ<br>
く旅行代金相当のポイントを控除することによって行われるものとします。<br>
５ 本会員は、成立した当社旅行契約の内容を、マイページを使用して確認することができ、<br>
マイページ上に表示される方法にて、当該当社旅行契約を解約（キャンセル）することがで<br>
きます。なお、当該キャンセルについては、当社旅行契約に定める取消料（キャンセル料）<br>
がかかる場合があります。<br>
（ポイント付与、保有、利用）<br>
第１０条 本会員が、第８条第１項に定める宿泊料など、別途マイページ上で当社が指定す<br>
る当社がサービス提供主体のサービスの代金を登録カードまたは現金（サービスによって<br>
は現金の使用はできません）により当社に支払いを行い、当社が認めた場合、当社が別途マ<br>
イページ上に定める付与基準等にもとづき、当該本会員に本ポイントを付与します。<br>
２ 本会員は、前各項により取得した本ポイントの保有状況を、マイページ上において確認<br>
できます。<br>
３ 本会員は、自らが保有する本ポイントを、第８条第５項に定める宿泊料など、別途マイ<br>
ページ上に定める当社指定のサービスの代金の支払いに、別途マイページ上に定める規定<br>
にもとづき、利用することができます。<br>
４ 本ポイントの付与（取得）、利用等にともない、税金や付帯費用が発生する場合には、<br>
本会員がこれらを負担するものとします。<br>
５ 本ポイントに関する取扱いの詳細は、ポイント規約によるものとします。<br>
（ファミリー会員）<br>
第１１条 個人の本会員は、自らの責任において、自らの成人の家族 を、当社が別途マイ<br>
ページ上に定める手続きを行い、当社が承認した場合、本会員に付随するファミリー会員<br>
（以下「ファミリー会員」という。）とすることができます。<br>
２ 本会員は、前項にかかわらず、第３条第２項各号に該当する者をファミリー会員とする<br>
ことができません。また、ファミリー会員が第３条第２項各号に該当する者となった場合、<br>
または、ファミリー会員が、本会員規約等においてファミリー会員が負う義務もしくは本会<br>
員が負う義務と同等の義務に違反した場合、当社は、ファミリー会員にすることの承認を取<br>
り消すことができるものとします。<br>
３ ファミリー会員は本会員に付随する会員とし、本会員は、ファミリー会員に対し、本会<br>
員規約等を周知し、遵守させなければいけません。また、本会員は、ファミリー会員の責務<br>
（本宿泊契約および当社旅行契約上の責務を含むがこれに限らない）について連帯して債<br>
務を負ものとします。<br>
４ ファミリー会員は、次の各号の内容に限り、本会員の責任のもと、本会員サービスを利<br>
用できます。なお、当該利用については、別途定めがある場合を除いては、次の各号に記載<br>
の各条項等について、「本会員」を「ファミリー会員」と置き換えて適用されます。<br>
（１）第７条第１項に定めるマイページの使用<br>
（２）第９項および第１０項にもとづく本会員が保有する本ポイントの利用<br>
（３）第８条に定める東急バケーションズ施設の優遇<br>
（４）第９条に定める提携施設の宿泊に関する旅行の優遇<br>
（５）前各号のほか当社がマイページ上においてファミリー会員向けに案内するサービス<br>
５ 前項第３号による東急バケーションズ施設への宿泊については、ファミリー会員また<br>
はファミリー会員が同伴する者のみ宿泊できるものとし、第三者のみの宿泊はできないも<br>
のとします。<br>
６ ファミリー会員は、次の各号の方法（詳細はマイページに定める。）により、本宿泊契<br>
約における宿泊料を、本宿泊契約にもとづき当社に支払うものとします。<br>
（１）東急バケーションズ施設での現金での支払い<br>
（２）東急バケーションズ施設でのファミリー会員名義のクレジットカードでの支払い<br>
（３）前各号の他、当社が定める支払い方法<br>
７ ファミリー会員が、前項に定める宿泊料など、別途マイページ上で当社が指定する当社<br>
がサービス提供主体のサービスの代金を現金（サービスによっては現金の使用はできませ<br>
ん）により当社に支払いを行い、当社が認めた場合、当社が別途マイページ上に定める付与<br>
基準等にもとづき、当該ファミリー会員の属する本会員に本ポイントが付与されます。<br>
８ 第４項第４号による旅行については、ファミリー会員が同行しない旅行はできません。<br>
９ 本会員が保有する本ポイントは、ファミリー会員がファミリー会員のマイページを使<br>
用して利用することができるものとし、本会員は、これを承知し、自らの責任により、ファ<br>
ミリー会員に利用させるものとします。<br>
10 前項のファミリー会員による本会員が保有する本ポイントの利用によって、贈与税そ<br>
の他の税金等が発生する場合には、本会員とファミリー会員間でこれに対応するものとし、<br>
甲に対し、何ら請求しないものとします。<br>
（法人の本会員）<br>
第１２条 法人の本会員は、自らの役職員をもって本会員サービスを利用するにあたって、<br>
当該役職員に対し、本会員規約等を周知し、遵守させなければいけません。また、法人の本<br>
会員は、自らの役職員が利用した本会員サービスにもとづき発生した責務について連帯し<br>
て債務を負い、または連帯して保証するものとします。<br>
２ 法人の本会員に対するマイページは、法人に対し１つのマイページが当社より提供さ<br>
れるものとします。法人である本会員は、自らの役職員をもって、当該マイページを利用さ<br>
せるときには、自らの責任をもって利用させるものとします。<br>
３ 法人の本会員は、本ポイントについて、自らの役職員に譲渡すること、また、分散して<br>
保有することはできないものとします。<br>
（権利義務の譲渡禁止）<br>
第１３条 本会員は、本会員規約等から生じた権利義務の全部または一部を第三者に譲渡<br>
し、もしくは担保に供し、または引き受けさせてはならない。<br>
（本会員に対する通知）<br>
第１４条 本会員規約等にもとづく当社の本会員に対する通知は、別途特段の定めがある場<br>
合を除き、マイページまたは申込サイト上に入力し当社に提示された電子メールアドレス、<br>
住所、電話番号のいずれかに対し行えば足りるものとします。<br>
（変更届）<br>
第１５条 本会員は、マイページまたは申込サイト上に入力し当社に提示された氏名、住所、<br>
電話番号、電子メールアドレス等の変更が生じた場合、遅延なく、当社が別途、マイページ<br>
に定める方法によって、当社に当該変更内容を届出るものとします。<br>
2 本会員が前項による届出を怠り、または当社からの通知を受領しないなど本会員が責を<br>
負うべき事由により当社からの通知が遅延し、もしくは到達しなかったときには、本会員規<br>
約等に基づく当社から本会員への通知は、通常到達すべきときに到達したものとみなされ<br>
ます。<br>
（遅延損害金）<br>
第１６条 本会員が当社に対する金銭債務の弁済を遅延したときは、本会員は、その弁済期<br>
の翌日から支払完了日まで、年１４．６％の割合による遅延損害金を付加して当社に支払う<br>
ものとします。<br>
（株式会社東急シェアリング）<br>
第１７条 本会員規約等に関するお問合せの受付、申込・届出・通知等の当社の業務は、当<br>
社が業務を委託する株式会社東急シェアリングが原則実施します。<br>
２ 当社は、本会員規約等に関する当社の業務の一部または全部を第三者に委託すること<br>
ができるものとします。<br>
（遵守事項）<br>
第１８条 本会員は、本会員規約等に定める事項を遵守しなければなりません。<br>
２ 本会員は、自己の責任において、当社より付与された会員番号（以下「会員番号」とい<br>
う。）、マイページにログインするためのパスワードおよびユーザーID を適切に管理および<br>
保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をして<br>
はならないものとします。<br>
３ 会員番号、パスワードおよびユーザーID の管理不十分、使用上の過誤、第三者の使用<br>
等によって生じた損害に関する責任は本会員が負うものとし、当社は一切の責任を負いま<br>
せん。会員番号、パスワードおよびユーザーID を用いたマイページによる、またはその他<br>
による、各種手続き等は、本会員ご本人による利用とみなします。また、会員番号、パスワ<br>
ードおよびユーザーID について、使用上の過誤または第三者による不正使用などがあった<br>
場合において、当社がその責任を負わないことを本会員は予め同意するものとします。<br>
４ 本会員は、自己の ID を失念したときは、速やかにマイページ所定の新規 ID 登録の手<br>
続きをとるものとし、また、パスワードを失念したときは、速やかにマイページ所定のパス<br>
ワード再発行の手続きをとるものとします。<br>
５ 本会員は、次の各号のいずれかを発見した場合には、すみやかに、次の連絡先まで通報<br>
するものとします。<br>
 ＜連絡先＞ ０１２０ʷ６１８ʷ１０９<br>
（１）自らもしくは第三者による本会員規約等に違反する行為<br>
（２）東急バケーションズ施設、本提携宿泊施設（いずれも設備等を含む。）の損傷その他<br>
の異常<br>
（３）マイページの異常<br>
（４）前各号のほか、本会員契約上の当社の履行に影響を及ぼす事象<br>
６ 本会員は、当社から求められた場合、当社に対し、会員番号、自らの身分証明証を提示<br>
するものとします。<br>
７ 本会員は、本会員サービスを利用しようとする場合、最新の本会員規約等、および当該<br>
利用に関連する情報を、マイページにおいて確認するものとします。<br>
（禁止事項）<br>
第１９条 本会員は、次の各号に定める事項を行うことができません。<br>
（１）本会員契約により生じる権利を利用して、本施設等内で営業行為を行うこと、第三者<br>
に有償で本施設等を使用させるなどの営業行為、または、本会員サービスを営業目的で利用<br>
する行為。<br>
（２）本施設等内において勧誘、思想信条等の表明、広報行為、またはその準備を目的とし<br>
た行為。<br>
（３）本施設等を公序良俗に反する行為、法令・条例に違反する行為、犯罪行為もしくは犯<br>
罪行為に結びつく行為、またはそれらのおそれのある行為で利用すること。<br>
（４）本会員契約により生じる権利を第三者に貸与し、または担保の用に供すること。<br>
（５）本会員契約により生じる権利を第三者に譲渡すること。<br>
（６）当社もしくは第三者の知的財産権、肖像権、名誉、プライバシー権その他の権利また<br>
は利益を侵害する行為<br>
（７）他の本会員その他の第三者または当社、東急グループの会社もしくは本提携宿泊施設<br>
などの当社のパートナー企業（以下「パートナー企業」という。）等の役職員等の特定の個<br>
人を誹謗中傷、非難、侮辱するなどして、迷惑をかけ、または不利益もしくは損害を与える<br>
行為、またはそれらのおそれのある行為。<br>
（８）本会員サービスの利用の際に虚偽の内容を申告する行為。<br>
（９）他の本会員その他の第三者または当社、東急グループの会社もしくはパートナー企業<br>
等のメールアドレス、氏名、名称その他の個人情報等を不当に使用する行為。<br>
（１０）当社、または本施設等の存する建物の運営者に対する本会員自らまたは第三者を利<br>
用した次の各項目のいずれかに該当する行為。<br>
①暴力的な方法をもって行う要求行為<br>
②不当な要求行為<br>
③脅迫的な言動をし、または暴力を用いる行為<br>
④風説を流布し、偽計を用いて信用を毀損し、または、業務を妨害する行為<br>
⑤その他前記①から④に準ずる行為<br>
（１１）前各号の他、本施設等の運営、本会員サービスの提供を妨げ、または当該運営もし<br>
くは提供に支障をきたすおそれのある行為。<br>
（１２）前各号の他、本会員規約等において禁止されている事項。<br>
（１３）前各号の他、当社が別途マイページ上に定める行為。<br>
（本会員の死亡）<br>
第２０条 本会員が死亡した場合、本会員の法定相続人は、当該本会員の死亡日より１０ヶ<br>
月経過する日もしくは本会員契約期間が終了する日のどちらか早く到来する日（以下「本承<br>
継期日」という。）までに、法定相続人の中から本会員の有する本会員契約上の権利義務（本<br>
会員規約等にもとづく権利義務を含む。以下、同様とする。）の一切を承継する者（ただし、<br>
第３条第２項各号に該当しない者）を１名（以下「本会員相続人」という。）を選定し、当<br>
社所定の書面を当社に提出するものとする。当社が当該書面を受理し、本会員相続人が、第<br>
４項に定める名義書換料を、当社所定の方法により当社に支払い、第３条第１項に定める要<br>
件を充足した場合には、本会員相続人は、当該本会員が死亡した日に遡って、本会員契約上<br>
の当該本会員の地位および権利義務の一切を承継します。<br>
２ 前項の手続きが完了し、承継が適切に完了するまでの間、本会員の法定相続人は、本会<br>
員の有する本会員契約および本会員規約等上の権利にもとづく一切を行使することはでき<br>
ないものとします。<br>
３ 本承継期日までに、第１項の手続きが完了しない場合には、本会員契約にもとづく本会<br>
員の権利義務（ただし、本会員の死亡日以前に生じている当社に対する未履行の債務は除<br>
く。）は消滅し、本会員契約（本宿泊契約および当社旅行契約等を含む）は当該本会員が死<br>
亡した日をもって終了したものといたします。この場合、当社は、法定相続人に対し、既に<br>
受領済みの年会費等の返還等の義務を負いません。<br>
４ 名義書換料は、次の額とし、当社は、経済事情の変化等に応じて、これを改定できるも<br>
のとします。<br>
名義書換料： 金５５，０００円（うち消費税額等５，０００円）<br>
（地位承継の特例）<br>
第２１条 本会員は、次の各号のいずれかに該当する場合、かつ、当社所定の手続きを行い、<br>
当社が承諾する場合、自らの責任により、法定相続人の中から１名（以下「本譲受人」とい<br>
う。ただし、第３条第１項を満足し、かつ、第３条第２項各号に該当しない者とします。）<br>
を選定し、本譲受人に対し、本会員の有する本会員契約上の権利義務にもとづく一切を承継<br>
させることができるものとします。なお、当該承継は、当社が承諾し、本譲受人が、前条第<br>
４項に定める名義書換料を、当社所定の方法により当社に支払い、第３条第１項に定める要<br>
件を充足し、当社が承継を確認した旨を本譲受人に通知したときをもって、効力が生じるも<br>
のとします。<br>
（１）満６５歳以上の場合<br>
（２）前各号のほか、当社がやむを得ぬ事情があると予め認める場合<br>
（法人会員の消滅）<br>
第２２条 法人である本会員について、解散等により法人格の消滅を生じさせる原因とな<br>
る事実が生じた場合、会社法上当然に本会員の権利義務にもとづく一切を承継する義務が<br>
ある者が承継する場合を除き、本会員契約（本会員契約に付随する契約を含む。）は、当然<br>
に終了する。<br>
２ 法人である本会員について、解散等により法人格の消滅を生じさせる原因となる事実<br>
が生じた場合において、会社法上当然に本会員の権利義務の一切を承継する義務がある者<br>
（以下「本承継法人」という。）は、当該承継後すみやかに、当社所定書面を提出し、第２<br>
０条第４項に定める名義書換料を、当社所定の方法により当社に支払い、また、第３条第１<br>
項に定める要件を充足させるものとします。<br>
３ 前項の手続きが完了するまでの間、本承継法人は、本会員の有する本会員契約上の権利<br>
にもとづく一切を行使することはできないものとします。<br>
（天災地変等による契約の終了）<br>
第２３条 地震、津波、暴風雨、洪水、戦争、暴動、内乱、反乱、革命、テロ、大規模火災、<br>
感染症、疫病、伝染病、ストライキ、ロックアウト、法令の制定・改廃、その他の当事者の<br>
合理的支配を超えた偶発的事象（以下「不可抗力」という。）によって、本施設等の継続使<br>
用が不能もしくは著しく困難となったとき、または、本会員契約上の当社の義務の履行を継<br>
続することが困難となったときは、当社は、本会員に通知または公表することによって本会<br>
員契約（本会員契約に付随する一切の契約を含む）の全部または一部を終了できるものとし<br>
ます。<br>
（解除）<br>
第２４条 本会員が次の各号の一に該当するときは、当社は、本会員契約（本会員契約に付<br>
随する契約を含む。本条において以下同様。）の全部または一部を解除することができるも<br>
のとします。<br>
（１）本施設等の名誉を毀損し、または秩序を乱したとき<br>
（２）本会員契約にもとづき生じる当社に対する支払いを滞納し、相当の期間を定めて催促<br>
したにもかかわらず当該支払いがなされなかったとき<br>
（３）前号のほか、本会員規約に違反したとき（ただし、軽微な違反のときには、相当の期<br>
間を定めて催促したにもかかわらず違反が是正されないときに限ります。）<br>
（４）第２条第１項に定める入会手続き時、その他で当社に提示、通知した内容、情報が事<br>
実と異なることが判明したとき<br>
（５）第３条第２項各号のいずれかに該当することになったとき、または、第３条第１項各<br>
号のいずれにも該当しなくなったとき<br>
（６）自己もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をも<br>
って本会員契約を締結したことが判明したとき<br>
（７）第２５条第１項に定める反社会的勢力に、本施設等を利用させたとき<br>
（８）第１９条の禁止事項を犯したとき<br>
（９）本会員規約第４条第１項に定める「登録カード」が当該カードのカード会社から無効<br>
とされたとき<br>
２ 本会員もしくは本施設等の客室への本会員の同伴者等が、前項各号のいずれかに該当<br>
したときには、当社は、本会員契約の全部または一部を解除することができるものとします。<br>
３ 前二項によって本会員契約が解除されたときには、本会員は、当社が被った損害を賠償<br>
する責任を免れることはできないものとします。<br>
（反社会的勢力の排除）<br>
第２５条 入会希望者および本会員は、本会への入会の申込時および本会員契約成立時に<br>
おいて、自己（自己が法人の場合は、代表者、役員または実質的に経営を支配する者。）が<br>
暴力団、暴力団員、暴力団関係企業、総会屋、社会運動標ぼうゴロ、特殊知能暴力集団等の<br>
反社会的勢力（以下「反社会的勢力」という。）に該当しないことを表明し、かつ将来にわ<br>
たっても該当しないことを確約します。<br>
２ 当社は、入会希望者または本会員、本施設等の客室への本会員の同伴者等が反社会的勢<br>
力に属すると判明した場合、催告をすることなく、本会員契約（本会員契約に付随する一切<br>
の契約を含む。本条において以下同様。）を解除することができるものとします。<br>
３ 当社は、前項の規定により、本会員契約を解除した場合には、これによる本会員の損害<br>
を賠償する責を負いません。<br>
４ 第２項の規定により本会員契約が解除された場合において、本会員は、当社に生じた損<br>
害について賠償する責を負います。<br>
（個人情報の取扱い）<br>
第２６条 当社は、入会希望者および本会員の氏名、生年月日、電話番号、住所、電子メー<br>
ルアドレス、クレジットカード番号、その他、入会希望者および本会員から当社に開示され<br>
た一切の個人情報（以下総称して「個人情報」という。）については、次の各号の目的のた<br>
めにこれを使用します。<br>
（１）本会員契約にもとづく当社の義務を履行するため（各種通知等を含む）<br>
（２）本会員サービスに関連する新たなサービス、本施設等に関連するサービス、その他当<br>
社および東急グループ各社のサービスに関連する広告宣伝等のダイレクトメールでの送<br>
付・電子メールでの配信等。（なお、入会希望者および本会員は、当社所定の方法で当社に<br>
届け出ることで、これらの送付等の中止、または再開を求めることができます。）<br>
（３）お問い合わせやご相談等への対応<br>
（４）本会員サービス等に関するアンケートの実施<br>
（５）本会員の満足度等を高めるために実施するマーケティング活動および商品開発<br>
（６）本会員から得た同意の範囲内での利用<br>
（７）前各号の事項に付随する業務<br>
（８）本会員サービスに関連する新たなサービス、本施設等に関連するサービス、その他当<br>
社のサービスに関連する業務における法律上必要な諸手続を履行すること<br>
（容認事項）<br>
第２７条 本会員は、次の各号の事項について予め承知、容認するものとし、次の各号の事<br>
項に起因して損害その他が生じた場合でも、当社の故意または重過失が認められるときを<br>
除き、当社に損害賠償その他何ら請求しないものとします。<br>
（１）本施設等に含まれる施設は増減する場合があること。<br>
（２）本会員サービスの内容は、経済事情の変化、ニーズの変化等により変更される場合が<br>
あること。<br>
（３）当社が、本施設の客室について、本商品または本会員規約第８条第１項による販売の<br>
他、顧客以外の第三者に対して第三者が運営する予約サイト等を通じて販売等すること。<br>
２ 当社は、本会員契約の継続が不能もしくは著しく困難になったとき、または、経営を継<br>
続することが困難なやむを得ない事由が生じたときは、本会員に対し通知のうえ、本会員契<br>
約（付随する一切の契約を含む）を解約することができるものとします。<br>
３ 本会員は、当社に支払った年会費、その他本会員契約にもとづき当社に支払ったその他<br>
の金員については、本会員契約に別途定めがある場合かつ当社の故意または重過失があっ<br>
た場合を除き、当社が一切返金しないことを、予め承諾します。<br>
４ 当社は、本会員契約にもとづき本会員に対して返金を行うにあたって、本会員規が当社<br>
に対して支払うべき金銭債務がある場合、当該債務の全部または一部を控除して、支払義務<br>
が生じた日の翌月末日までに、本会員が指定する日本国内の本会員名義の金融機関口座に<br>
振込み、返金することができるものとします。<br>
（免責事項）<br>
第２８条 当社は、次の各号のいずれかの場合については、当社の故意または重過失に起因<br>
するときを除き、何ら責任を負わないものとし、本会員は、当社に対し、損害賠償その他何<br>
ら請求しないものとします。<br>
（１） 本施設等の利用時において、本会員（同伴者その他で本会員が利用させる者を含む。<br>
本項において以下同様。）が、他の利用者などの第三者との間でトラブル、紛争等が<br>
生じた場合。なお、この場合、当該トラブル等については、本会員と当該第三者と<br>
の間で解決するものとします。<br>
（２）本会員契約に関連して、本会員が第三者（本施設等が入居する建物の運営者、を含む<br>
がこれに限らない。本号において以下同様。）との間で契約締結する場合、または、本会員<br>
が第三者の提供するサービスに申込み当該サービスの提供を受ける場合。なお、当該契約に<br>
ついては、本会員の責任で締結するものであり、当社は、何ら責任を負うものではありませ<br>
ん。<br>
（３）本会員が第１５条第１項による届出を怠り、または当社からの通知を受領しない等当<br>
社の責めに帰するべき事由がない場合において、本会員契約にもとづく本会員に対する通<br>
知が、遅延したこと、または到達しなかったことによって本会員に損害等が生じた場合。<br>
（４）不可抗力、あるいは、本施設等の点検・修繕その他の管理、その他の当社の責のない<br>
要因によって、本施設等の全部または一部が一時的に使用できなくなった場合、または本会<br>
員規約に定める本会員サービスその他本会員契約にもとづき当社が提供するサービスが提<br>
供できなくなった場合。<br>
（損害賠償）<br>
第２９条 本会員規約等に関し、当社が入会希望者、本会員（本会員の同伴者その他の本会<br>
員の関係人を含む）に負う損害賠償の責任範囲については、直接かつ通常の損害の範囲に限<br>
るものとし、逸失利益、予見しまたは予見できる事情による損害（特別損害）等については<br>
何ら責任を負わないことを、入会希望者および本会員は予め承諾します。<br>
（本会員契約終了時の取扱い）<br>
第３０条 本会員契約が終了した場合、本会員契約（本会員規約等を含む）に別途特段の定<br>
めがあるときを除き、当社は本会員より受領済みの年会費その他の金員を本会員に返金す<br>
る義務を負いません。ただし、当社に故意、重過失があった場合には、未利用の期間等に応<br>
じた年会費の相当額を本会員に返金するものとします。<br>
（分離可能性）<br>
第３１条 本会員規約等のいずれかの条項またはその一部が、消費者契約法その他の法令<br>
等により無効または執行不能と判断された場合であっても、本会員規約等の残りの規定お<br>
よび一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を<br>
有するものとします。<br>
（準拠法、管轄裁判所）<br>
第３２条 本会員規約等および本会員契約の準拠法は日本法とします。<br>
２ 本会員契約に関する本会員と当社との間で紛争が生じた場合は訴額に応じて東京地方<br>
裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とするものとします。<br>
（規約の改定）<br>
第３３条 本会員規約等は、民法第５４８条の２ 第１項に定める定型約款に該当し、当社<br>
は以下の各号の場合に、当社の裁量により本会員規約等を変更することがあります。<br>
（１）本会員規約等の変更が、本会員の一般の利益に適合するとき。<br>
（２）本会員規約等の変更が、本会員契約をした目的に反せず、かつ、変更の必要性、変更<br>
後の内容の相当性その他変更にかかる事情に照らして合理的なものであるとき。<br>
２ 前項により、当社が本会員規約等を変更する場合、本会員規約等を変更する旨および変<br>
更後の本会員規約等の内容ならびにその効力発生日について、効力発生日の１ヶ月前まで<br>
に、当社サイトに掲示し、または本会員に電子メールもしくは郵便で通知します。<br>
３ 変更後の本会員規約等の効力発生日以降に、本会員が本会員サービスを利用したとき<br>
は、本会員規約等の変更に同意したものとみなします。<br>
（定めのない事項）<br>
第３４条 本会員規約等に定めのない事項については、入会希望者または本会員は当社に確<br>
認し、その指示に従うものとします。<br>
以上<br>

2022 年７月１日 制定<br>
別紙<br>
第２条第１項 各種規約等とは、以下の規約類を指す。<br>
・Vacation 会員ページ規約<br>
・東急バケーションズポイント規約<br>
・その他当社が適宜追加する規約等<br>
以上
  </terms>
</template>

<script>
import Terms from './Terms.vue'
export default {
  components: { Terms }
}
</script>
