<template>
  <v-form ref="form" class="mt-9">
    <div class="text-title--szm --w-medium --dark">
      {{ $t('text.register.pleaseAnswer') }}
    </div>

    <div class="bg-white --border-r-4 pb-5 mb-10">
      <div class="bg-primary py-4 px-5 mt-3 --border-rt-4">
        <label class="text-medium-szm --white --w-bold">
          {{ $t('text.register.howDidHear') }}
          <span
            class="bg-pink text-small px-3 py-1 --border-r-2 --white"
          >{{ $t('reservationAndUsageHistory.mandatory') }}</span
          >
        </label>
      </div>
      <div class="px-5">
        <v-radio-group
          v-model="media"
          :rules="[$rules.required]"
        >
          <v-row class="ma-0 pt-3">
            <v-col
              class="pa-0"
              cols="6"
              v-for="item in howDidHearElements"
              :key="item"
            >
              <v-radio
                :value="item"
              >
                <template v-slot:label>
                  <span class="text-normal --w-medium --prm">{{ item }}</span>
                </template>
              </v-radio>
            </v-col>
          </v-row>
        </v-radio-group>
        <div v-if="showOther">
          <div class="text-normal --w-regular --dark">
            {{ $t('text.register.answeredOther') }}
          </div>
          <label for="otherMedia" class="text-normal --w-regular --dark">
            {{ $t('text.register.answeredOtherOptional') }}
          </label>
          <v-text-field
            id="otherMedia"
            v-model="howDidHearOther"
            :rules="rules.howDidHearOther"
            flat
            solo
            outlined
            class="mt-2 --border-r-4"
          />
        </div>
      </div>
    </div>

    <span class="mt-11 text-medium-szm --w-medium --dark">
      {{ $t('text.register.getStartBill') }}
    </span>
    <span class="text-medium-szm --w-regular --dark">
      {{ $t('text.register.confirmDesc') }}
    </span>

    <div class="mt-5">
      <vs-terms />
    </div>
    <div class="mt-5">
      <tokushoho />
    </div>

    <div class="mt-2">
      <v-checkbox
        v-model="agree"
        :label="$t('text.register.agree')"
        :value="true"
        :rules="[$rules.required]"
      ></v-checkbox>
    </div>
  </v-form>
</template>

<script>
import VsTerms from '@/components/Common/VsTerms'
import Tokushoho from '@/components/Common/Tokushoho'
export default {
  components: { VsTerms, Tokushoho },
  props: {
    value: Object
  },
  data () {
    return {
      howDidHearOther: null,
      media: null,
      agree: false,
      rules: {
        howDidHearOther: [
          v => {
            return this.media !== this.otherMedia || !!v || this.$t('rules.isRequired')
          }
        ]
      }
    }
  },
  computed: {
    otherMedia () {
      return this.$t('common.other')
    },
    howDidHearElements () {
      return [
        this.$t('text.register.webSearch'),
        this.$t('text.register.officialSNS'),
        this.$t('text.register.accommodationBooking'),
        this.$t('text.register.tv'),
        this.$t('text.register.magazine'),
        'DM',
        this.$t('text.register.introduction'),
        this.$t('text.register.JALVacations'),
        'ANA',
        this.otherMedia
      ]
    },
    mediaString () {
      let mediaString = this.media
      if (this.media === this.otherMedia) {
        mediaString = `その他(${this.howDidHearOther || 'その他未入力'})`
      }

      return mediaString
    },
    showOther () {
      return this.media === this.otherMedia
    }
  },
  created () {
    if (!this.value) { // set the default
      this.$emit('input', {
        media: null
      })
    }
  },
  watch: {
    howDidHearOther () { this.emitMedia() },
    media () { this.emitMedia() }
  },
  methods: {
    validate (...args) {
      return this.$refs.form.validate(...args)
    },
    emitMedia () {
      this.$emit('input', {
        media: this.mediaString
      })
    }
  }
}
</script>
