<template>
  <terms>
    特定商取引法に基づく表記（VacationStyle入会契約）<br>
    サービスの料金：メンバーシップフィー…年間4,400円（税込）※端数が生じる年でも日割り計算等は行わないものとします。<br>
    メンバーシップフィーの支払時期、方法：【支払時期】メンバーシップフィー…入会日の月日の属する月の翌月１日　【支払方法】メンバーシップフィー…入会申込手続きの際に、申込サイトにて表示される当社指定の第三者のクレジットカード決済手続き会社にお客様自身が別途登録したクレジットカードにてお支払いとなります。<br>
    サービス提供開始時期：申込サイト上に「登録完了」の旨が表示されたとき。<br>
    中途解約について：Vacation Style 会員規約に特段定める事項以外の中途解約はお受けしておりません。<br>
    サービス提供事業者：事業者東急株式会社東京都渋谷区南平台町５番６号０３－３４０６－０１５４<br>
    販売業者代表者：東京都渋谷区南平台町５番６号 東急ホテルズ＆リゾーツ株式会社 代表取締役社長　村井　淳<br>
    自動更新について：お客様が契約満了日までにVacation会員ページにて自動更新フラグをオフにしない限り、契約は満了日翌日から1年間自動更新されます。<br>
    公開メールアドレス：資料請求時の配信元アドレス：noreply@tokyu-sharing.co.jp、メールマガジンの配信元アドレス：marketing@tokyu-sharing.co.jp<br>
  </terms>
</template>

<script>
import Terms from './Terms.vue'
export default {
  components: { Terms }
}
</script>
